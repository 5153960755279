import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function IndexPage() {
    return (
        <Layout>
            <SEO title="Mexia Bodywork" keywords={[`massage`, `washington`, `therapy`, `mexia`]} />
        </Layout>
    );
}

export default IndexPage;
